<template>
  <div class="skeletonModalContainer">
    <div class="skeletonModalContainer__mainContent">
      <div class="skeletonModalContainer__mainContent--categories">
        <div class="category" v-for="index in categoryIterationCount" :key="index"></div>
      </div>
      <div v-if="!store.state.isMobile" class="skeletonModalContainer__mainContent--list">
        <div class="item" v-for="index in itemIterations" :key="index"></div>
      </div>
    </div>
    <div class="skeletonModalContainer__footer">
      <div class="skeletonModalContainer__footer--leftButton"></div>
      <div class="skeletonModalContainer__footer--rightButton"></div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'skeletonFilterModal',
  setup () {
    const store = useStore()
    const categoryIterations = ref(9)
    const categoryIterationsMobile = ref(4)
    const itemIterations = ref(10)

    /**
     * @description change iteration count depending on whether is mobile or not
     */
    const categoryIterationCount = computed(() => {
      return store.state.isMobile ? categoryIterationsMobile.value : categoryIterations.value
    })

    return {
      store,
      categoryIterations,
      categoryIterationsMobile,
      itemIterations,
      categoryIterationCount
    }
  }
}
</script>
